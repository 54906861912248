.modal-container {

  display: none;
  z-index: 1050;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);

  .modal-dialog {
    max-width: 600px;
    max-height: 80vh;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    z-index: 1051;

    .modal-content{
      border: none;
      border-radius: 4px;
      outline: none;

      .modal-header{
        border-bottom: none;
        padding: 2.5rem;
        align-items: center;
        cursor: default;

        h5{
          margin: 0px;
        }

        .btn-close {
          background-color: $color-grey-light;
          border: none;
          border-radius: 4px;
          font-size: 2rem;
          width: 35px;
          height: 35px;
        }

        .btn-close:hover {
          background-color: $color-grey-1;

        }
      }

      .modal-body {
        padding: 0 2.5rem 2.5rem 2.5rem;
        cursor: default;

        .modal-section {
          margin-bottom: 2.5rem;

          .form.custom-label{
            margin-bottom: 0px;
            font-weight: 700;
            color: $color-black;
            // line-height: 1em;
            font-size: 1.6rem;


            .tags-label-container{
              display: flex;
              background-color: $color-grey-light;
              padding: 1rem 2rem;
              border-radius: 4px;
            }

            .tags-label-container.black{
              display: flex;
              // font-weight: 700;
              color: $color-white;
              background-color: $color-black;
              border-radius: 5px;
              padding: 2rem;

              .grouped-tags.locale{
                display: flex;
                flex-direction: row;
                .text-muted {
                  background-color: $color-white;
                  width: fit-content;
                  padding: 0.5rem;
                  border-radius: 5px;
                  font-size: 75%;
                  font-style: italic;
                  color: $color-black !important;
                  margin-right: 1rem;
                }

              }



              .divider {
                height: auto;
                width: 1px;
                border-radius: 1px;
                background-color: $color-white;
                margin-left: 1rem;
                margin-right: 1rem;
              }

              .divider:last-child {
                display: none;
              }
            }



          }
        }

        .modal-section.tags {
          display: flex;
          flex-wrap: wrap;
          gap: 1rem;
          justify-content: center;
        }
      }

      .modal-footer {
        border-top: none;
        background-color: $color-grey-light;
      }
    }
  }

}


.modal-container.show{
  display: flex;
  align-items: center;
  justify-content: center;
}
