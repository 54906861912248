.ps-loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: #f9f9f9;
    flex-flow: row nowrap;
    @include transition(all 0.5s $timing-3);

    &__content {
        height: 100vh;
        display: flex;
        flex-flow: row nowrap;

        > * {
            display: block;
            width: 25%;
            @include transition(transform 1s $timing-1);
            @include transform-origin(0 50%);
            background-color: #fcfaf6;
        }
    }


    &.loaded {
        @include hidden;
        background-color: transparent;
        @include transition-delay(0.7s);

        .ps-loading__content {
            > * {
                @include transform(scale3d(0, 1, 1));

                @include transition-delay(0.5s);
            }
        }
    }
}

#loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    overflow: hidden;
    .loader-section {
        position: fixed;
        top: 0;
        width: 51%;
        height: 100%;
        background: #ffffff;
        z-index: 999;
        &.section-left {
            left: 0;
        }
        &.section-right {
            right: 0;
        }
    }
}

#loader-wrapper

#loader-wrapper .loader-section

#loader-wrapper .loader-section.section-right {
    right: 0;
}



/* Loaded styles */

.loaded #loader-wrapper .loader-section.section-left {
    transform: translateX(-100%);
    //transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.7s 0.3s $timing-3;
    transition-delay: 0.1s;
}

.loaded #loader-wrapper .loader-section.section-right {
    transform: translateX(100%);
    /*transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);*/
    transition: transform 0.7s 0.3s $timing-3;
    transition-delay: 0.1s;
}

.loaded #loader-wrapper {
    visibility: hidden;
  /*  transform: translateY(-100%);*/
    transition: all 0.3s 1s ease-out;
}
