.ps-panel--sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 400px;
    height: 100vh;
    overflow-y: auto;
    z-index: 10001;
    background-color: #fff;
    @include transform(translateX(-100%));
    transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1) 0s;
    @extend %list-reset;

    .ps-panel__header {
        position: relative;
        text-align: center;
        padding: 15px 20px;
        background-color: $color-1st;

        h3 {
            margin-bottom: 0;
            font-weight: 600;
            color: #ffffff;
            font-size: 1.6rem;
            line-height: 20px;
            text-transform: uppercase;
            letter-spacing: 0.05em;
        }

        .ps-btn--close {
            @include vertical-align();
            right: 10px;

            &:before,
            &:after {
                background-color: #ffffff;
                height: 60%;
            }
        }
    }

    .ps-panel__content {
        padding-top: 10px;
        padding-bottom: 70px;
    }

    &.active {
        @include transform(translateX(0));
    }

    @include media('<xs') {
        width: 100%;
        max-width: 100%;
    }
}

.ant-drawer {
    z-index: 9999 !important;
    @media screen and (max-width: 768px) {
        .ant-drawer-content-wrapper {
            min-width: 100%;
        }
    }
}

.ps-panel--mobile {
    .ant-menu-inline {
        border-right: none !important;

        .ant-menu-submenu-title {
            padding: 0 !important;
        }

        .ant-menu-item {
            padding: 0 !important;
        }
    }

    .ant-drawer-body {
        padding: 0 !important;
    }

    .ps-panel__header {
        position: relative;
        text-align: center;
        padding: 15px 20px;
        background-color: $color-1st;

        h3 {
            margin-bottom: 0;
            font-weight: 600;
            color: #ffffff;
            font-size: 1.6rem;
            line-height: 20px;
            text-transform: uppercase;
            letter-spacing: 0.05em;
        }

        .ps-btn--close {
            @include vertical-align();
            right: 10px;

            &:before,
            &:after {
                background-color: #ffffff;
                height: 60%;
            }
        }
    }

    .ps-panel__content {
        padding: 0 20px;
    }

    .ps-panel__search-results {
        padding-top: 20px;
        min-height: 70vh;
        overflow: auto;
        max-height: 100%;
        .ps-form--search-mobile {
            margin-bottom: 30px;
            input {
                border: 1px solid #ddd;
            }
        }
    }

    .ant-menu {
        .ant-menu-item {
            &.ant-menu-item-selected {
                background-color: $color-1st;
                &:after {
                    display: none;
                }
                a {
                    padding: 0 10px;
                    color: #fff;
                    transition: all .25s ease;
                }
            }
        }
    }
}

.ps-panel--wrapper {
    height: 100vh;
    .ps-panel__close {
        @include vertical-align();
        right: 20px;
        font-size: 18px;
    }

    .ps-panel__header {
        position: relative;
    }

    .ps-panel__content {
        height: calc(100vh - 50px);
    }
}
