.hidden {
  display: none;
}

.full {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.public-web-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.public-web-container p {
  position: absolute;
  top: 40%;
}

.public-web-container a {
  position: absolute;
  bottom: 40%;
}

.wechat-web-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wechat-web-container p {
  position: absolute;
  top: 40%;
}

.wechat-web-container wx-open-launch-weapp {
  position: absolute;
  bottom: 40%;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.desktop-web-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.desktop-web-container p {
  position: absolute;
  top: 40%;
}
