.header_placeholder {
  display: none;
  height: 0px;
  width: 100px;

  &.header--placeholder--open {
    display: block;
    height: 96px;
    @include media('<lg') {
        display: none;
    }
  }

}

.header {
    @extend %list-reset;

    .header__extra {
        display: inline-block;
        position: relative;
        width: 30px;
        height: 42px;
        transition: all 0.4s ease;

        > i {
            color: $color-brand-white;
            font-size: 28px;
            line-height: 42px;
            transition: all 0.4s ease;
        }

        span {
            position: absolute;
            bottom: 0;
            right: -6px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            vertical-align: top;
            width: 20px;
            height: 20px;
            color: $color-brand-white;
            background-color: $color-brand-1;
            border-radius: 50%;
            transition: all 0.4s ease;

            i {
                font-size: 12px;
                font-style: normal;
                line-height: 1em;
                font-weight: 900;

            }
        }
        span.active {
          display: none;

          i {
              font-size: 12px;
              font-style: normal;
              line-height: 1em;
              font-weight: 900;

          }
        }

        &:hover {
            i {
              color: $color-brand-1-pressed;
            }

            span {
              color: $color-brand-white;
              background-color: $color-brand-1;
              i {
                color: $color-brand-white;
              }
            }
        }


    }

    .header__actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .header_divider{
          border-width: 0 0 0 2px;
          border-style: solid;
          border-color: $color-brand-white;
          height: 30px;
          margin-right: 10px;
        }
        > *:not(:last-child) {
            margin: 0 20px;
        }
    }

    .header__left {
        max-width: 200px;
        display: flex;
        margin-right: 30px;
        align-items: center;

        .menu--product-categories {
            display: none;
        }
    }

    .header__center {
    }

    .header__right {
        max-width: 300px;
    }

    .header__top {
        padding: 25px 0;
        background-color: $color-brand-black;
        border-bottom: 4px solid $color-brand-1-pressed;

        // border-bottom: 1px solid rgba(0, 0, 0, 0.15);

        .ps-container,
        .container {
            display: flex;
            flex-flow: row nowrap;

            > * {
                width: 100%;
            }
        }
    }

    .navigation--mobile {
        .header__extra {
            width: 40px;
        }
    }

    &.header--sticky {
        .menu--product-categories {
            .menu__toggle {
                i {
                    font-size: 30px;
                }

                span {
                    font-size: 16px;
                }
            }
        }

        .header__top {
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 1000;
            border-bottom: none;
            padding: 10px 0;
        }

        .header__left {
            .ps-logo {
                // display: none;
                display: block;

            }

            .menu--product-categories {
                display: block;
                background-color: transparent;
            }
        }
    }

    @include media('<lg') {
        display: none;
        &.header--mobile {
            display: block;
        }

        .header__extra {


            > i {
                color: $color-brand-black;

            }
          }
    }
}

.header--standard {
    .ps-block--user-header {
        a {
            &:hover {
                color: $color-1st;
            }
        }
    }

    .header__top-links {
        width: 100%;

        > li {
            display: inline-block;
            margin-right: 16px;
            padding-right: 15px;
            position: relative;

            &:after {
                content: '';
                @include vertical-align();
                right: 0;
                height: 15px;
                width: 0;
                border-right: 1px solid #e1e1e1;
            }

            > a {
                color: #666666;
            }

            &:last-child {
                margin-right: 0;
                padding-right: 0;

                &:after {
                    display: none;
                }
            }
        }

        .language {
            > a {
                img {
                    margin-right: 5px;
                }
            }
        }

        .ps-block--user-header {
            .ps-block__left {
                i {
                    font-size: 14px;
                }
            }

            .ps-block__right {
                a {
                    display: inline-block;
                    font-weight: 400;
                    margin-right: 10px;
                    color: #666666;

                    &:first-child {
                        &:after {
                            content: '/';
                            padding-left: 10px;
                        }
                    }

                    &:hover {
                        color: $color-1st;
                    }
                }
            }
        }
    }

    .header__top {
        padding: 10px 0;
        background-color: #fff;
        border-bottom: none;

        .container {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
        }

        .header__left {
            max-width: 50%;

            p {
                margin-bottom: 0;
                color: #666666;
                line-height: 30px;

                strong {
                    color: $color-heading;
                }
            }
        }

        .header__right {
            text-align: right;
            max-width: 50%;
        }
    }

    .header__content-left {
        max-width: 270px;
        padding-right: 30px;
        display: flex;
        align-items: center;

        .ps-logo {
            margin-right: 30px;
        }
    }

    .header__content-center {
        max-width: 600px;
    }

    .menu--product-categories {
        .menu__toggle {
            i {
                font-size: 30px;
            }
        }
    }

    .ps-form--quick-search {
        width: 600px;



        button {
            background-color: #fcb800;
            color: $color-brand-white;
        }

        .form-group--icon {
            border: 1px solid #e1e1e1;
            min-width: 80px;
            border-radius: 4px 0 0 4px;

            select {
                height: 40px;
                border: none;
            }
        }

        input {
            border: 1px solid #e1e1e1;
            border-left: none;
        }
    }

    .header__content {
        padding: 20px 0;

        .menu--product-categories {
            .menu__toggle {
                &:hover {
                    &:before {
                        display: none;
                    }
                }
            }
        }

        .container {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            > * {
                width: 100%;
            }
        }
    }

    .header__extra {
        span {
            background-color: $color-brand-white;

            i {
                color: $color-heading;
                font-weight: 700;
            }
        }
    }

    &.header--sticky {
        .header__top {
            position: relative;
        }

        .header__content {
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 1000;
            border-bottom: 1px solid #ccc;
            padding: 10px 0;
            background-color: #ffffff;

            .menu--product-categories {
                display: block;
            }
        }

        .ps-logo {
            display: none;
        }
    }

    @include media('<lg') {
        display: none;
    }
}

.header--product {
    /*  @include hidden;
    height: 0;*/

    .navigation--product {
        transition: transform 0.25s ease;
        transform: translateY(-50%);
        @include hidden();
        height: 0;
    }

    &.header--sticky {
        @include show;
        height: auto;

        .header__top {
            display: none;
        }

        .navigation {
            height: 0;
        }

        .navigation--product {
            padding: 10px 0;
            background-color: #fff;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 1000;
            height: auto;
            border-bottom: 1px solid #eaeaea;
            transform: translateY(0);
            height: auto;
            @include show;
        }
    }
}

.header--mobile {
    position: relative;
    top: 0;
    width: 100%;
    left: 0;
    z-index: 1000;
    text-align: center;
    border-bottom: 0 none;
    background-color: $color-1st;
    transition: all 0.4s $ease-in-out-quad;
    @include clearfix();

    .navigation__extra {
        display: inline-block;

        li {
            // margin-right: 5px;
            // padding-right: 5px;



            &:after {
                display: none;
            }
        }
    }

    .header__extra {
        span {
            background-color: $color-brand-white;
        }
    }

    .header__actions {
        i {
            font-size: 24px;
        }
    }

    .ps-block--user-header {
        i {
            font-size: 24px;
        }
    }

    .header__top {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        background-color: $color-brand-black;
        padding: 15px 30px;

        > * {
            width: 100%;
        }

        .header__left {
            max-width: 40%;

            p {
                color: $color-brand-white;
                margin-bottom: 0;
            }


        }

        .header__right {
            max-width: 60%;
            text-align: right;

            .navigation__extra {
                display: inline-block;

                  li.active {
                    a {
                      color: $color-brand-white;
                    }
                  }
                   a.active {
                     color: $color-brand-white;
                  }

            }
        }
    }

    .navigation--mobile {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding: 10px 30px;
        border-bottom: none;

        .navigation__left {
            display: flex;
            align-items: center;
        }
    }

    .header__extra {
        span {
            background-color: #fff;

            i {
                color: #ffffff;
                font-weight: 600;
            }
        }
    }

    &.active {
        @include transform(translateX(270px));
    }

    &.header--sticky {
        .navigation--mobile {
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 1000;
            background-color: $color-1st;
            @include box-shadow(0 0 3px 0 rgba(#000, 0.12));
        }
    }

    @include media('<lg') {
        .ps-search--mobile {
            display: none;
        }
    }

    @include media('<md') {
        .header__top {
            .header__left {
                display: none;
            }

            .header__right {
                max-width: 100%;
                text-align: center;
            }
        }
    }

    @include media('<sm') {
        .navigation__extra {
            li {
                > a {
                    display: none;
                }
            }
        }
    }

    @include media('<xs') {
        .header__top {
            display: none;
        }

        .header__actions {
            > * {
                margin: 0;
                margin-right: 20px;
            }

            i {
                font-size: 24px;
            }
        }

        .ps-block--user-header {
            margin-right: 0;

            i {
                font-size: 24px;
            }

            .ps-block__right {
                display: none;
            }
        }

        .ps-search--mobile {
            display: block;
        }

        .navigation--mobile {
            padding: 10px 20px;
        }
    }

    @media (min-width: 1200px) {
        display: none;
    }
}

.header--mobile-product {
    .header__back {
        vertical-align: middle;
        text-align: left;

        strong {
            font-size: 18px;
            vertical-align: middle;
        }

        i {
            vertical-align: middle;
            margin-right: 0.5em;
            font-size: 24px;
            color: $color-heading;
        }
    }

    .navigation--mobile {
        .navigation__left {
            justify-content: flex-start;
        }
    }
}

.header--mobile-categories {
    .header__back {
        vertical-align: middle;
        text-align: left;

        strong {
            font-size: 18px;
            vertical-align: middle;
        }

        i {
            vertical-align: middle;
            margin-right: 16px;
            font-size: 24px;
            color: $color-heading;
        }
    }

    .navigation--mobile {
        .navigation__left {
            justify-content: flex-start;
        }
    }

    .header__filter {
        padding: 10px 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #f5f5f5;

        > * {
            flex-basis: 100%;
            max-width: 50%;
        }

        button {
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30px;
            background-color: transparent;
            font-size: 14px;

            &:after {
                content: '\e93a';
                font-family: Linearicons;
                margin-left: 20px;
            }

            i {
                margin-right: 10px;
                font-size: 16px;
                font-size: 20px;
                color: $color-text;
            }
        }

        .header__sort {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 10px;

            i {
                margin-right: 10px;
                font-size: 16px;
                font-size: 20px;
                color: $color-text;
            }

            .select2 {
                .select2-selection {
                    border: none;
                    background-color: transparent;

                    .select2-selection__rendered {
                        background-color: transparent;
                        text-align: left;
                    }

                    .select2-selection__arrow {
                        &:before {
                            content: '\e93a';
                            font-family: Linearicons;
                        }
                    }
                }
            }
        }

        > button {
            border-right: 1px solid #ccc;
        }
    }
}
