.ps-skeleton {
    overflow: hidden;
    .row {
        > * {
            overflow: hidden;
        }
    }
}

.ps-skeleton--product {
    margin-bottom: 30px;
}
